.about-page {
  text-align: center;
  color: #ffffff;
  background: url('../assets/about-bg.jpg') no-repeat center center/cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Header Section */
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.small-logo {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 4%;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #1E90FF;
}

/* Hero Section */
.hero-section {
  margin-top: 100px;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
}

/* About Content */
.about-content {
    margin: 20px auto;
    max-width: 95%;
    min-height: 400px;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    font-size: 1.2rem;
    line-height: 1.4;
    text-align: left;
}

/* Promotional Content Styles */
.promo-header {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
}

.promo-header .promo-subheader {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
}

.promo-header p {
  font-size: 2.5rem;
}

.promo-subheader {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
}

.promo-content p {
    font-size: 1em;
    line-height: 1.6;
    margin-bottom: 5px;
}

.promo-list {
    list-style-type: disc;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 10px;
}

.promo-list li {
    margin-bottom: 10px;
}

.cta-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #007bff;
    color: #fff;
    font-size: 1.2em;
    text-decoration: none;
    border-radius: 5px;
    margin-top: 20px;
}

.cta-button:hover {
    background-color: #0056b3;
}

.about-left, .about-right {
  flex: 1;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.about-left {
  margin-right: 10px;
}

.about-right {
  margin-left: 10px;
}

.about-left p {
  margin: 10px 0;
  line-height: 1.6;
}
/* Footer Section */
.main-footer {
  display: flex;
  flex-direction: column; /* Stack social icons and copyright vertically */
  align-items: center; /* Center-align both horizontally and vertically */
  justify-content: center;
  padding: 10px 0;
  background-color: transparent;
}

.about-page .social-media {
  display: flex; /* Ensure social icons are in a row */
  gap: 20px; /* Add spacing between icons */
  justify-content: center; /* Center the icons horizontally */
  margin-bottom: 4%; /* Add spacing below the icons */
}

.social-media a {
  font-size: 3rem;
  color: #ffffff;
  text-decoration: none;
}

.social-media a:hover {
  color: #1E90FF;
}

.copyright {
  text-align: center; /* Center the text content */
  margin: 0 auto; /* Center the element */
  font-size: 1.2rem;
  color: #ffffff;
  position: absolute; /* Ensure it's at the bottom */
  bottom: 2%; /* Position it slightly above the page bottom */
  left: 50%; /* Start centering */
  transform: translateX(-50%); /* Adjust to align center */
}
