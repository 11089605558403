.login-page {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background: url('../assets/login-bg.jpg') no-repeat center center/cover;
  height: 100vh;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.login-content {
  margin: 60px auto;
  max-width: 400px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.login-content h1{
  text-align: center;
}

.login-form {
  display: block;
  padding: 0px 30px 30px 30px;
}

.login-form label {
  margin-top: 0px;
  font-weight: bold;
  display: block;
  padding: 10px;
}

.login-form input {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.login-form .cta-button {
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 1.2rem;
  background-color: #1E90FF;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.forgot-password {
  margin-bottom: 20px;
  text-align: center;
  margin-top: -10px;
}

.forgot-password a {
  color: #1E90FF;
  text-decoration: none;
}

.disclaimer {
  font-size: 0.8em;
  color: #ababab;
  padding: 3%;
  text-align: center;
}
