.contact-page {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background: url('../assets/contact-bg.jpg') no-repeat center center/cover;
  height: 100vh;
}

/* Header Section */
.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.small-logo {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 4%;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.nav-links li a:hover {
  color: #1E90FF;
}

.login-button {
  color: #222;
}

/* Hero Section */
.hero-section {
  margin-top: 100px;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

/* Contact Content */
.contact-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 60px 10% 10px;
  min-height: 60vh; /* Set the block to 70% of the screen height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  border-radius: 10px;
  padding: 20px;
}

.contact-info {
  flex: 1;
  padding: 20px;
  font-size: 1.2rem;
  line-height: 1.6;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  border-radius: 10px;
  text-align: center;
}

.contact-text {
  text-align: left;
  margin: 0% 0% 0% 10%;
}

.contact-details {
  margin: 20px 20px;
}

.contact-response {
  font-size: 1rem;
  margin: 1% auto;
  font-style: italic;
}

.contact-serving {
  font-size: 1.6rem;
}

.contact-form {
  flex: 1;
  padding: 20px;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  border-radius: 10px;
}

.contact-form label {
  margin-top: 10px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.contact-form button {
    margin: 3% 9% 0% 9%;
    padding: 10px 20px;
    font-size: 2rem;
    background-color: #222;
    color: #ffffff;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 80%;
}
.contact-form button:hover {
    padding: 8px 20px;
    font-size: 2.2rem;
    background-color: #000;
    color: #ffffff;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    width: 80%;
}

.submit-text{
  color: fff;
  text-align: center;
}

/* Footer Section */
.main-footer {
  padding: 10px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
}

.main-footer p {
  margin: 0px auto;
}

.social-media {
  display: block;
  margin: auto;
}

.social-media a {
  font-size: 3rem;
  margin: 3%;
  color: #ffffff;
}

.social-media a:hover {
  color: #1E90FF;
}

.copyright {
  text-align: center; /* Center the text content */
  margin: 0 auto; /* Center the element */
  font-size: 1.2rem;
  color: #ffffff;
  position: absolute; /* Ensure it's at the bottom */
  bottom: 2%; /* Position it slightly above the page bottom */
  left: 50%; /* Start centering */
  transform: translateX(-50%); /* Adjust to align center */
}

.recaptcha {
  display: flex;
   justify-content: center;
   align-items: center;
   margin: 10px 0; /* Optional: Adjust spacing around the widget */
}
