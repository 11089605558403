/* Header Container */
.main-header {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.0); /* Semi-transparent black background */
  color: #ffffff;
  z-index: 1000;
  padding: 30px 0;
}

.main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Logo Styling */
.logo {
  font-size: 1.8rem;
  font-weight: bold;
}

/* Navigation Bar */
nav {
  display: flex;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  display: inline-block;
}

.nav-links a {
  color: #ffffff;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #1E90FF;
}
