/* Global reset for all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  color: var(--text-color);
  background-color: var(--background-color);
}

a {
  text-decoration: none;
  color: var(--text-color);
}

button {
  cursor: pointer;
}

.login-button {
  color: red;
}
