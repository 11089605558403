/* General Reset */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  color: #ffffff;
  background-color: #000000;
}

.landing-page {
  text-align: left; /* Align content to the left */
  background: url('../assets/landing-bg.jpg') no-repeat center center/cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Content Container */
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align to the left */
  padding-left: 5%; /* Indent from the left */
  height: 70vh;
}

/* Header Section */
.landing-header h1 {
  font-size: 2.8rem;
  font-weight: bold;
  margin: 10px 0;
  line-height: 1.4;
}

.buttons {
  margin-top: 20px;
}

.landing-header .cta-button {
  padding: 12px 45px;
  margin: 10px 60px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 0 50px 4px #fff; /* Glow effect on hover */
  text-transform: uppercase;
}

.cta-button.secondary {
  background-color: transparent;
  border: 2px solid #ffffff;
}

.cta-button:hover {
  opacity: 0.9;
}

/* Countdown Section */
.countdown {
  display: flex;
  justify-content: flex-start; /* Keep alignment flexible */
  align-items: center; /* Vertically align with social icons */
  gap: 20px; /* Add space between the circles */
  position: absolute; /* Position relative to the page */
  bottom: 15%; /* Raise it slightly above the footer */
  left: 55%; /* Move it to the right of the social icons */
}

.countdown-item {
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 120px; /* Larger size for better visibility */
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1); /* Add slight background for visibility */
}

.countdown-item span {
  font-size: 2.4rem;
  font-weight: bold;
  color: #ffffff;
}

.countdown-item p {
  font-size: 0.9rem;
  margin: 0;
  color: #ffffff;
}

/* Footer Section */
.landing-footer {
  padding: 2% 0 2% 8%; /* Balanced vertical and horizontal padding */
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  gap: 25px; /* Icon spacing */
}

.landing-page .social-media {
    display: inline-flex;
    margin: 2% 0.5%;
    gap: 0px;
    padding: 0px;
}

.social-media a {
  font-size: 3rem; /* Large and visible icons */
  color: #ffffff;
  text-decoration: none;
}

.social-media a:hover {
  color: #1E90FF; /* Blue hover effect */
}

.copyright {
  margin-top: 10px;
  text-align: center;
  font-size: 0.9rem;
  color: #ffffff;
}

/* Countdown End Section */
.countdown-end {
  text-align: center;
  margin-top: 30px;
  color: #ffffff;
}

.countdown-end h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.countdown-end p {
  font-size: 1.2rem;
  line-height: 1.5;
}
