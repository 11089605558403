.reset-password-page {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background: url('../assets/reset-password-bg.jpg') no-repeat center center/cover;
  height: 100vh;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}

.reset-password-content {
  margin: 10% auto; /* Centers the form horizontally and vertically */
  max-width: 400px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
}


.reset-password-content h1 {
  padding: 10px;
  text-align: center;
  font-size: 1.7em;
}

.reset-password-content h2 {
  margin-top: -50px;
  margin-bottom: 20px;
  font-size: 1.2em;
}

.reset-password-content p {
  color: #ababab;
  text-align: justify;
  padding: 15px;
}

.reset-password-form label {
  margin-top: 0px;
  font-weight: bold;
  display: block;
  padding: 10px;
}

.reset-password-form input {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.reset-password-form .cta-button {
  margin-top: 20px;
  padding: 12px 20px;
  font-size: 1rem;
  background-color: #1E90FF;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.copyright {
  text-align: center; /* Center the text content */
  margin: 0 auto; /* Center the element */
  font-size: 1.2rem;
  color: #ffffff;
  position: absolute; /* Ensure it's at the bottom */
  bottom: 2%; /* Position it slightly above the page bottom */
  left: 50%; /* Start centering */
  transform: translateX(-50%); /* Adjust to align center */
}
